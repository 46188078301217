import React from 'react';
import { Helmet } from 'react-helmet';
import { headData } from '../mock/data';
import Navbar from '../components/Navbar/Navbar';

export default () => {
  return (
    <>
      <Navbar />
      <div>Narudžba pacijenta</div>
    </>
  );
};
